<template>
  <div class="main-content">
    <h4 class="blue-text font-weight-bold">
      <router-link :to="{ name: 'userRoute-staging-delivered' }">
        <font-awesome-icon
          class="mr-3"
          :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
        />
      </router-link>
      <span>修正依頼</span>
    </h4>
    <div class="col-12" v-if="listingDetailsData">
      <div v-if="summary === false">
        <div id="uploaded-image" class="row" v-if="current_version">
          <div class="col-12 col-md-12 col-lg-12 col-xl-9">
            <div class="row">
              <div class="col-12">
                <p class="blue-text font-weight-bold">
                  <span class="details-title">ステージング画像</span>
                  <span class="float-right">
                    {{ pagination.data.current_page }}/{{
                      pagination.data.last_page
                    }}
                    枚
                  </span>
                </p>
              </div>
              <div
                class="col-12 col-md-12 col-lg-12 col-xl-6"
                v-if="current_version"
              >
                <p class="font-weight-bold">
                  <span class="details-title">現在のバージョン</span>
                </p>
                <div class="image-wrapper pb-3">
                  <div class="image">
                    <img
                      :src="current_version.result_file_url"
                      onerror="this.src='/img/image-error.png'"
                      @click="openModal(current_version.result_file_url)"
                    />
                    <a
                      href="#"
                      @click.prevent="
                        downloadItem(
                          current_version.result_file_path,
                          `SR-${listingDetailsData.id}_current_version_${current_version.delivery_date}`
                        )
                      "
                    >
                      <div class="download-wrapper float-right">
                        <font-awesome-icon
                          :icon="{ prefix: 'fas', iconName: 'circle-down' }"
                          :style="{
                            color: '#034EA1',
                            height: '36px',
                            width: '36px',
                            top: '-60px',
                          }"
                        />
                      </div>
                    </a>
                  </div>
                  <div class="details-date">
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">納品日時:</span>
                      <span class="font-weight-bold">
                        {{ formatDate(stagingListing.delivery_date) }}
                      </span>
                    </p>
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">確認期限:</span>
                      <span class="font-weight-bold">
                        {{ formatDate(stagingListing.deadline_date) }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-md-12 col-lg-12 col-xl-6"
                v-if="old_versions.length > 0"
              >
                <p class="font-weight-bold">
                  <span class="details-title">以前のバージョン</span>
                </p>
                <div class="image-wrapper pb-5">
                  <div class="image">
                    <b-carousel
                      class="img-carousel"
                      :interval="0"
                      :controls="old_versions.length > 1 ? true : false"
                      :indicators="old_versions.length > 1 ? true : false"
                      @sliding-start="onSlideStart"
                    >
                      <b-carousel-slide
                        v-for="(el, i) in old_versions"
                        :key="i"
                      >
                        <template #img>
                          <img
                            :src="el.result_file_url"
                            onerror="this.src='/img/image-error.png'"
                            :id="'carousel-img-' + i"
                            :data-delivery-date="formatDate(el.delivery_date)"
                            @click="openModal(el.result_file_url)"
                          />
                        </template>
                        <a
                          href="#"
                          @click.prevent="
                            downloadItem(
                              el.result_file_path,
                              `SR-${listingDetailsData.id}_${el.delivery_date}`
                            )
                          "
                        >
                          <div
                            class="download-wrapper float-right old-version-carousel"
                          >
                            <font-awesome-icon
                              :icon="{ prefix: 'fas', iconName: 'circle-down' }"
                              :style="{
                                color: '#034EA1',
                                height: '36px',
                                width: '36px',
                                top: '-60px',
                              }"
                            />
                          </div>
                        </a>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                  <div class="details-date">
                    <p class="timestamp">
                      <span class="font-weight-bold mr-2">納品日時:</span>
                      <span class="font-weight-bold">
                        {{ formatDate(stagingListing.delivery_date) }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="comments" class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-9">
            <p class="blue-text font-weight-bold">修正依頼コメント</p>
            <b-form-textarea
              v-model="
                comments.find(
                  (el) => el.request_item_id === listingDetailsData.id
                ).comment
              "
              placeholder="以下の特殊な記号は使えません。 !@#$%^&*()[]"
              rows="10"
              max-rows="10"
            ></b-form-textarea>
            <p class="blue-text">修正不要の場合はコメントの入力は必要ありません</p>
            <p class="warning-text">
              ●修正コメントに関しての注意事項<br>
              良い例：木目調の家具にしてください、ソファーの色を暗めの茶色にしてください<br>
              悪い例：〇〇がターゲットのインテリア、〇〇に好かれるような家具、〇〇のような暮らしのイメージの家具や色<br>
              ※具体的な家具や配置のイメージがある場合は、お手数ですが配置図や写真データなどを別途当社までメールをお願いいたします<br>
              info@digitalstaging.jp<br>
              参考となる家具などのURLを修正コメントに入れていただくことも可能です<br><br>
            </p>
            <div class="actions">
              <button
                class="btn btn-ds mt-3 light-grey white-text"
                v-waves.light
                v-on:click="goToPrevious(pagination.data.from)"
                v-if="pagination.data.from !== pagination.currentPage"
              >
                <font-awesome-icon
                  :icon="{ prefix: 'fas', iconName: 'chevron-left' }"
                />
                <span class="ml-3">もどる</span>
              </button>
              <button
                class="btn btn-ds mt-3 dark-blue white-text"
                v-waves.light
                v-on:click="goToNext(pagination.data.from)"
                v-if="pagination.data.from !== pagination.data.last_page"
              >
                <span class="mr-3">つぎへ</span>
                <font-awesome-icon
                  :icon="{ prefix: 'fas', iconName: 'chevron-right' }"
                />
              </button>
              <button
                class="btn btn-ds mt-3 dark-blue white-text"
                v-waves.light
                v-on:click="setSummary"
                v-else
              >
                <span class="mr-3">OK</span>
                <font-awesome-icon
                  :icon="{ prefix: 'fas', iconName: 'chevron-right' }"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h4 class="mt-3 blue-text font-weight-bold">確認</h4>
        <div v-if="summaryEmptyState === false">
          <div v-for="(el, i) in comments" :key="i">
            <div class="row summary-item" v-if="el.comment">
              <div class="col-12 col-md-5 col-lg-4 col-xl-4 image">
                <img
                  :src="el.image"
                  onerror="this.src='/img/image-error.png'"
                  @click="openModal(el.image)"
                />
              </div>
              <div class="col-12 col-md-7 col-lg-8 col-xl-8 details">
                <h5 class="font-weight-bold">修正依頼コメント:</h5>
                <pre><p class="mt-3">{{ el.comment ? el.comment : 'No Comment' }}</p></pre>
              </div>
            </div>
          </div>
          <b-alert
            class="m-0 mt-3 light-grey-outline grey-text"
            variant="warning"
            show
            v-if="formMessage !== null"
          >
            {{ formMessage }}
          </b-alert>
        </div>
        <div v-else>
          <b-alert
            class="m-0 mt-3 light-grey-outline grey-text"
            variant="warning"
            show
          >
            コメントを記入してください。
          </b-alert>
        </div>

        <div class="actions">
          <button
            class="btn btn-ds mt-3 light-grey white-text"
            v-waves.light
            v-on:click="goToPrevious(pagination.data.from + 1)"
          >
            <font-awesome-icon
              :icon="{ prefix: 'fas', iconName: 'chevron-left' }"
            />
            <span class="ml-3">もどる</span>
          </button>
          <button
            class="btn btn-ds mt-3 dark-blue white-text"
            v-on:click="submitCorrection"
            v-waves.light
          >
            送信
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-9">
          <div class="row mt-4">
            <div class="col-12 mb-3">
              <b-skeleton width="20%"></b-skeleton>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
              <b-skeleton class="mt-3 mb-2" width="100%"></b-skeleton>
              <b-skeleton-img width="100%"></b-skeleton-img>
              <b-skeleton class="mt-3 mb-2" width="100%"></b-skeleton>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-6 mb-4">
              <b-skeleton class="mt-3 mb-2" width="100%"></b-skeleton>
              <b-skeleton-img width="100%"></b-skeleton-img>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 col-md-12 col-lg-12 col-xl-9">
          <b-skeleton class="mb-3" width="20%"></b-skeleton>
          <b-skeleton-img height="30vh" width="100%"></b-skeleton-img>
        </div>
      </div>
    </div>
    <image-modal @closeModal="closeModal()" v-if="isOpenModal">
      <img :src="modalImageUrl">
    </image-modal>
  </div>
</template>

<script>
// Import Services
import {
  cus_stagingService_GetAllSRItems,
  cus_stagingService_GetSRData,
  cus_stagingService_CreateCR,
} from '../../services/customer/staging';
import { globalService_DownloadIMG } from '../../services/global';

// Import Moment JS
import moment from 'moment';

//import common
import { getRequestItemVersions } from '../../common/Functions';
import { formatDate, formatPrice } from '../../common/Utility';

// Import Modal
import ImageModal from '../../components/customer/Image-Modal.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | Delivered - View',
    };
  },

  data() {
    return {
      stagingListing: null,
      listingDetailsData: null,
      old_versions: [],
      current_version: null,
      pagination: {
        data: null,
        limit: 3,
        showDisabled: false,
        size: 'default',
        align: 'center',
        currentPage: this.$store.state.currentPaginationPage,
      },
      comments: [],
      summary: false,
      summaryEmptyState: null,
      formMessage: null,
      delivery_date: '',
      isMix:false,
      isOpenModal: false,
      modalImageUrl: null,
    };
  },

  components: {
    'image-modal': ImageModal,
  },

  mounted() {
    this.$store.state.currentPaginationPage = 1;

    this.setListingDetailsData();
  },

  updated() {
    if (this.delivery_date == '') {
      this.setDeliveryDate(0); // set delivery date of 1st image in carousel
    }
  },

  methods: {
    openModal(imgUrl) {
      this.modalImageUrl = imgUrl;
      this.isOpenModal = true;
    },
    closeModal(){
      this.isOpenModal = false;
    },
    formatDate,
    downloadItem(path, filename) {
      let accessToken = localStorage.getItem('_accessToken');

      globalService_DownloadIMG({
        token: accessToken,
        path: path,
        isMix:this.isMix,
      }).then((response) => {
        let url = window.URL.createObjectURL(response.data);
        let link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);

        link.click();
      });
    },
    setDeliveryDate(i) {
      // get delivery date from data attribute of image in carousel
      var el = document.getElementById('carousel-img-' + i);
      if (el) {
        this.delivery_date = el.getAttribute('data-delivery-date');
      }
    },
    onSlideStart(slide) {
      this.setDeliveryDate(slide);
    },
    setListingDetailsData() {
      let _this = this;

      let stagingRequestID = location.pathname.split(
        '/staging/delivered/correction/'
      )[1];
      let accessToken = localStorage.getItem('_accessToken');
      let page = _this.$store.state.currentPaginationPage;

      _this.stagingListing = null;
      _this.listingDetailsData = null;

      cus_stagingService_GetSRData({
        id: stagingRequestID,
        token: accessToken,
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.stagingListing = data
        })
        .catch((error) => {
          console.log(error);
        });

      cus_stagingService_GetAllSRItems({
        id: stagingRequestID,
        paginated: 1,
        page_size: 1,
        page: page,
        token: accessToken,
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.listingDetailsData = data.data[0];
          _this.pagination.data = data;

          //get staging versions
          const { current_version, prev_versions } = getRequestItemVersions(
            data.data[0]
          );
          _this.current_version = current_version;
          for (var i=0;i<prev_versions.length;i++){
            if (prev_versions[i].user_type == undefined || prev_versions[i].user_type == 0 ){
              _this.old_versions.push(prev_versions[i]);
            }
          }
          _this.setCommentModel({
            request_item_id: data.data[0].id,
            image: current_version.result_file_url,
            comment: null,
          });
          _this.delivery_date = '';
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setCommentModel(obj) {
      let objExist = this.comments.find(
        (el) => el.request_item_id === obj.request_item_id
      );

      if (!objExist) {
        this.comments.push(obj);
      }
    },

    goToPrevious(from) {
      this.$store.state.currentPaginationPage = from -= 1;

      this.summary = false;

      this.setListingDetailsData();
    },

    goToNext(from) {
      this.$store.state.currentPaginationPage = from += 1;

      this.setListingDetailsData();
    },

    setSummary() {
      this.summary = true;

      this.summaryEmptyState = true;

      this.comments.forEach((el) => {
        if (el.comment) {
          this.summaryEmptyState = false;
        }
      });
    },

    submitCorrection() {
      let _this = this;

      _this.$store.state.modalLoaderMessage = '送信中';
      _this.$bvModal.show('modal-loader');

      let accessToken = localStorage.getItem('_accessToken');

      let filteredComment = _this.comments
        .map((el) => {
          if (el.comment) {
            return el;
          }
        })
        .filter((e) => e != null);

      if (filteredComment.length > 0) {
        cus_stagingService_CreateCR({
          items: filteredComment,
          token: accessToken,
        })
          .then((response) => {
            console.log(response);

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
              _this.$router.push({
                name: 'userRoute-staging-delivered',
              });
            }, 500);
          })
          .catch((error) => {
            console.log(error);

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
          });
      } else {
        _this.formMessage = 'コメントが必要です。';

        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
.details-title {
  font-size: 19px;
  font-style: normal normal medium 19px/23px Roboto;
}
.details-title {
  margin-bottom: 43px;
}
.details span,
.details p {
  font-size: 16px;
}
#uploaded-image {
  margin-top: 40px;
}
#uploaded-image .image {
  height: 340px;
  background: #ececec;
  border-radius: 15px;
  padding: 0;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  object-fit: cover;
}
.image-wrapper {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 15px;
}
.summary-item .image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  max-height: 300px;
}

.summary-item .details {
  padding-top: 10px;
}

.download-wrapper {
  position: absolute;
  right: 36px;
  bottom: 12px;
  background: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.download-wrapper.old-version-carousel {
  right: -40px;
  bottom: -8px;
}

.image-wrapper {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 15px;
}

@media only screen and (max-width: 1440px) {
  #uploaded-image .image {
    height: 230px;
  }
  .summary-item .image img {
    height: 200px;
  }
  .download-wrapper.old-version-carousel {
    right: -20px;
  }
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .image {
    height: 400px;
  }
}

@media only screen and (max-width: 425px) {
  #uploaded-image .image {
    height: 250px;
  }
}

#uploaded-image .image img {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  object-fit: cover;
}

#uploaded-image .image .img-carousel {
  height: 100%;
  border-radius: 15px;
  background: #e9e9e9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#uploaded-image .timestamp {
  margin: 10px 0 0 20px;
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .timestamp {
    margin: 10px 0 0 0;
    text-align: center;
  }
}

@media only screen and (max-width: 1199px) {
  #uploaded-image .details {
    margin-top: 60px;
  }
}

#comments {
  margin-top: 40px;
}

@media only screen and (max-width: 1199px) {
  #comments {
    margin-top: 20px;
  }
}

.actions {
  margin-top: 40px;
  text-align: right;
}

.actions .btn {
  width: 200px;
  margin-left: 40px;
}

@media only screen and (max-width: 767px) {
  .actions {
    text-align: center;
  }

  .actions .btn {
    width: 100%;
    margin-left: 0;
  }
}

.summary-item {
  margin: 20px 2px 2px 2px;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: #f5f5f5;
}

.summary-item:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.summary-item .image {
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .summary-item .image {
    margin-bottom: 20px;
  }
}
</style>
